import { useMemo } from "react";

import {
  IColumn,
  IconButton,
  mergeStyleSets,
  ShimmeredDetailsListProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { dangerButtonStyles } from "@components/buttons/DangerButton";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { RolloutSoftwarePackageVersionDto } from "./";
import { RolloutPackageTableRow } from "./RolloutPackageTableRow";
import { RolloutPackageVersionSelect } from "./RolloutPackageVersionSelect";

export interface RolloutPackageTableProps extends ShimmeredDetailsListProps {
  onDelete: (softwarePackageId: string) => void;
  onUpdateSoftwarePackageVersion: (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => void;
  onConfigureRolloutPackage: (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => void;
  rolloutOccurred: boolean;
}

export const RolloutPackageTable = ({
  onDelete,
  onUpdateSoftwarePackageVersion,
  onConfigureRolloutPackage,
  rolloutOccurred,
  ...props
}: RolloutPackageTableProps) => {
  const theme = useTheme();
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "name",
        name: "Name",
        minWidth: 150,
        maxWidth: 800,
        isResizable: true,
        onRender: (
          softwarePackageVersion: RolloutSoftwarePackageVersionDto
        ) => (
          <Stack
            styles={{
              root: { whiteSpace: "initial" }
            }}
          >
            <Text>{softwarePackageVersion.displayName}</Text>
            <Text variant="small">
              Description: {softwarePackageVersion.description}
            </Text>
            <Text variant="small">Code: {softwarePackageVersion.code}</Text>
          </Stack>
        )
      },
      {
        key: "version",
        name: "Version",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        onRender: (
          softwarePackageVersion: RolloutSoftwarePackageVersionDto
        ) => (
          <RolloutPackageVersionSelect
            rolloutSoftwarePackageVersion={softwarePackageVersion}
            onUpdate={softwarePackageVersionId => {
              onUpdateSoftwarePackageVersion(
                softwarePackageVersion.softwarePackageId,
                softwarePackageVersionId
              );
            }}
            rolloutOccurred={rolloutOccurred}
          />
        )
      },
      {
        key: "softwarePackageType",
        name: "Software Type",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: (
          softwarePackageVersion: RolloutSoftwarePackageVersionDto
        ) => <Text>{softwarePackageVersion.softwarePackageType}</Text>
      },
      {
        key: "softwarePublisher",
        name: "Software Publisher",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onRender: (
          softwarePackageVersion: RolloutSoftwarePackageVersionDto
        ) => <Text>{softwarePackageVersion.softwarePublisherCode}</Text>
      },
      {
        key: "datePublishedUtc",
        name: "Date Published",
        minWidth: 150,
        isResizable: true,
        onRender: (
          softwarePackageVersion: RolloutSoftwarePackageVersionDto
        ) => (
          <Text>
            {DateTime.fromISO(
              (softwarePackageVersion.datePublishedUtc as unknown) as string
            )?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT) ??
              "(not available)"}
          </Text>
        )
      },
      {
        key: "configure",
        name: "",
        minWidth: 30,
        maxWidth: 30,
        onRender: (
          softwarePackageVersion: RolloutSoftwarePackageVersionDto
        ) => (
          <IconButton
            onClick={() => {
              onConfigureRolloutPackage(
                softwarePackageVersion.softwarePackageId,
                softwarePackageVersion.softwarePackageVersionId
              );
            }}
            styles={{
              root: {
                background: "transparent"
              }
            }}
            iconProps={{ iconName: "Settings" }}
          />
        ),
        sort: false
      },
      {
        key: "id",
        name: "",
        minWidth: 30,
        maxWidth: 30,
        onRender: (
          softwarePackageVersion: RolloutSoftwarePackageVersionDto
        ) => (
          <IconButton
            onClick={() => {
              onDelete(softwarePackageVersion.softwarePackageId);
            }}
            styles={mergeStyleSets(dangerButtonStyles(theme), {
              root: {
                background: "transparent"
              }
            })}
            iconProps={{ iconName: "Cancel" }}
            disabled={rolloutOccurred || !hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          />
        ),
        sort: false
      }
    ];
  }, [
    theme,
    onDelete,
    onUpdateSoftwarePackageVersion,
    onConfigureRolloutPackage,
    hasWritePermission,
    rolloutOccurred
  ]);

  return (
    <ShimmeredDetailsList
      stickyHeader
      shimmerLines={5}
      columns={columns}
      onShouldVirtualize={() => true}
      onRenderRow={row => (row ? <RolloutPackageTableRow {...row} /> : null)}
      {...props}
    />
  );
};
