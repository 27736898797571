import React, { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  DefaultButton,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Text,
  Toggle,
  useTheme
} from "@bps/fluent-ui";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useRollouts } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { RolloutListItem } from "./RolloutListItem";

interface RolloutListProps {
  deploymentRingId: string;
}

export const RolloutList: React.FC<RolloutListProps> = ({
  deploymentRingId
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: rollouts } = useRollouts(deploymentRingId);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const navigateToNewRollout = () => {
    const to = generatePath(
      "/system/plt/deployment-rings/:deploymentRingId/rollouts/new",
      {
        deploymentRingId
      }
    );
    navigate(to);
  };

  const [showEnabled, setShowEnabled] = useState<boolean>(true);
  return (
    <Stack
      tokens={{ childrenGap: theme.spacing.m }}
      styles={{ root: { width: "77%" } }}
    >
      {/* Rollouts section */}
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Stack horizontal tokens={{ childrenGap: theme.spacing.l1 }}>
            <Text variant="xLarge">Rollouts</Text>
            <Toggle
              id="show-enabled"
              onChange={() => setShowEnabled(!showEnabled)}
              inlineLabel
              label="Show enabled only"
              automationAttribute="click-show-enabled"
              checked={showEnabled}
            />
          </Stack>
          <Stack.Item align="end">
            <DefaultButton
              disabled={!hasWritePermission}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              iconProps={{ iconName: "Add" }}
              onClick={navigateToNewRollout}
            >
              Add Rollout
            </DefaultButton>
          </Stack.Item>
        </Stack>

        <Stack
          styles={{
            root: {
              height: 800
            }
          }}
        >
          <Stack.Item
            styles={{
              root: {
                position: "relative",
                flexGrow: 1
              }
            }}
          >
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                {rollouts
                  ?.filter(r =>
                    showEnabled ? r.isActive === showEnabled : true
                  )
                  .map(rollout => (
                    <RolloutListItem key={rollout.id} rollout={rollout} />
                  ))}
              </Stack>
            </ScrollablePane>
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};
