import { ValidationSchema } from "@bps/utils";
import { Validator } from "@components/form/validation/Validator";

import { convertToDateTime } from "../utils";
import { RolloutFormValues } from "./rolloutFormValues";

export const isValidRolloutDateAndTime = (options: {
  downloadStartDate: Date | undefined;
  downloadStartTime: string | undefined;
  downloadEndDate: Date | undefined;
  downloadEndTime: string | undefined;
  installStartDate: Date | undefined;
  installStartTime: string | undefined;
  installEndDate: Date | undefined;
  installEndTime: string | undefined;
}): string | undefined => {
  const {
    downloadStartDate,
    downloadStartTime,
    downloadEndDate,
    downloadEndTime,
    installStartDate,
    installStartTime,
    installEndDate,
    installEndTime
  } = options;
  if (
    !(
      downloadStartDate &&
      downloadStartTime &&
      downloadEndDate &&
      downloadEndTime &&
      installStartDate &&
      installStartTime &&
      installEndDate &&
      installEndTime
    )
  )
    return undefined;

  const downloadStartDateTime = convertToDateTime(
    downloadStartDate,
    downloadStartTime
  );

  const downloadEndDateTime = convertToDateTime(
    downloadEndDate,
    downloadEndTime
  );

  const installStartDateTime = convertToDateTime(
    installStartDate,
    installStartTime
  );

  const installEndDateTime = convertToDateTime(installEndDate, installEndTime);

  if (downloadStartDateTime >= downloadEndDateTime) {
    return "Initial download start date and time must be before initial download end date and time";
  }

  if (installStartDateTime >= installEndDateTime) {
    return "Initial installation start date and time must be before initial installation end date and time";
  }

  if (
    downloadStartDateTime >= installStartDateTime ||
    downloadStartDateTime >= installEndDateTime ||
    downloadEndDateTime >= installStartDateTime ||
    downloadEndDateTime >= installEndDateTime
  ) {
    return "Initial download start and end must be before initial installation start and end";
  }

  return undefined;
};

export const rolloutFormValidator = new Validator<RolloutFormValues>();
const displayNameRequired = rolloutFormValidator
  .string()
  .required({ message: "Display Name is required" });

const dateRequired = rolloutFormValidator
  .date()
  .required({ message: "Date is required" });

const timeRequired = rolloutFormValidator
  .date()
  .required({ message: "Time is required" });

export const rolloutFormSchema: ValidationSchema<RolloutFormValues> = {
  displayName: [
    rolloutFormValidator.string().maxLength(255, {
      message: "Maximum length is 255 characters"
    }),
    displayNameRequired
  ],
  initialDownloadDateStartUtc: dateRequired,
  initialDownloadDateEndUtc: dateRequired,
  initialDownloadTimeStartUtc: timeRequired,
  initialDownloadTimeEndUtc: timeRequired,
  initialInstallDateStartUtc: dateRequired,
  initialInstallDateEndUtc: dateRequired,
  initialInstallTimeStartUtc: timeRequired,
  initialInstallTimeEndUtc: timeRequired
};
