import { Route, Routes } from "react-router-dom";

import { DeployRings } from "./DeployRings";
import { EditRollout } from "./rollout/EditRollout";
import { NewRollout } from "./rollout/NewRollout";

export const DeploymentRingsRouter = () => {
  return (
    <Routes>
      <Route path=":ringId/rollouts/:rolloutId" element={<EditRollout />} />
      <Route path=":ringId/rollouts/new" element={<NewRollout />} />
      <Route path=":ringId" element={<DeployRings />} />
      <Route path="*" element={<DeployRings />} />
    </Routes>
  );
};
