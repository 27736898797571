import { useCallback, useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import {
  DefaultButton,
  Heading,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { IsDevelopmentEnvironment } from "@libs/config/config";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { RolloutPackages } from "../rollout-package";
import { AddRolloutPackageDialog } from "../rollout-package/add";
import {
  RolloutPackageConfigureDto,
  RolloutPackageDesiredConfigDialog
} from "../rollout-package/configure";
import { hasRolloutOccurred } from "../utils";
import { isValidRolloutDateAndTime } from "./RolloutFormValidator";
import {
  RolloutFormValues,
  RolloutPackageDesiredConfigFormValues
} from "./rolloutFormValues";
import { RolloutTimePicker } from "./RolloutTimePicker";

interface RolloutFormProps {
  rollout: Rollout;
  onSubmit: (values: RolloutFormValues) => void;
}

export const RolloutForm: React.FC<RolloutFormProps> = ({
  rollout,
  onSubmit
}) => {
  const theme = useTheme();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showConfigure, setShowConfigure] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | undefined>();

  // The software package whose desired configuration we are editing.
  const [configureSoftwarePackage, setConfigureSoftwarePackage] = useState<
    RolloutPackageConfigureDto | undefined
  >();

  const { control, setValue, getValues, ...form } = useFormContext<
    RolloutFormValues
  >();

  const rolloutOccurred =
    hasRolloutOccurred(rollout) && !IsDevelopmentEnvironment;

  // When we click the configure icon on a rollout package, set the current software package ID's and show the edit dialog.
  const configureRolloutPackage = (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => {
    setConfigureSoftwarePackage({
      softwarePackageId,
      softwarePackageVersionId
    });

    setShowConfigure(true);
  };

  const onUpdateSoftwarePackageDesiredConfigs = (
    configs: RolloutPackageDesiredConfigFormValues[]
  ) => {
    const existingValues = getValues();
    const updatedPackages =
      existingValues.rolloutPackages?.map(rolloutPackage => {
        const { desiredConfigs, ...packageRest } = rolloutPackage;
        return {
          ...packageRest,
          desiredConfigs:
            rolloutPackage.softwarePackageId ===
            configureSoftwarePackage?.softwarePackageId
              ? configs
              : desiredConfigs
        };
      }) ?? [];

    setValue("rolloutPackages", updatedPackages, { shouldDirty: true });
  };

  const _onSubmit = useCallback<SubmitHandler<RolloutFormValues>>(
    values => {
      const {
        initialDownloadDateStartUtc,
        initialDownloadDateEndUtc,
        initialDownloadTimeStartUtc,
        initialDownloadTimeEndUtc,
        initialInstallDateStartUtc,
        initialInstallDateEndUtc,
        initialInstallTimeStartUtc,
        initialInstallTimeEndUtc
      } = values;

      const error = isValidRolloutDateAndTime({
        downloadStartDate: initialDownloadDateStartUtc,
        downloadStartTime: initialDownloadTimeStartUtc,
        downloadEndDate: initialDownloadDateEndUtc,
        downloadEndTime: initialDownloadTimeEndUtc,
        installStartDate: initialInstallDateStartUtc,
        installStartTime: initialInstallTimeStartUtc,
        installEndDate: initialInstallDateEndUtc,
        installEndTime: initialInstallTimeEndUtc
      });

      setValidationError(error);

      if (error) return;

      onSubmit({
        ...values
      });
    },
    [onSubmit]
  );

  return (
    <Stack
      as="form"
      tokens={{ childrenGap: theme.spacing.s1 }}
      onSubmit={form.handleSubmit(_onSubmit)}
    >
      {validationError && (
        <MessageBar messageBarType={MessageBarType.error}>
          {validationError}
        </MessageBar>
      )}

      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        <FormTextField
          control={control}
          name="displayName"
          label="Display Name"
        />

        <FormSwitch
          name="isActive"
          label="Enabled"
          data-test="switch-isactive"
        />
      </Stack>
      <Stack tokens={{ childrenGap: theme.spacing.l1 }}>
        <StackItem>
          <Heading labelPaddings>Initial Download Window (UTC Time)</Heading>
          <Stack tokens={{ childrenGap: theme.spacing.m }}>
            <RolloutTimePicker
              rolloutOccurred={rolloutOccurred}
              datePickerName="initialDownloadDateStartUtc"
              timePickerName="initialDownloadTimeStartUtc"
              isStart={true}
            />

            <RolloutTimePicker
              rolloutOccurred={rolloutOccurred}
              datePickerName="initialDownloadDateEndUtc"
              timePickerName="initialDownloadTimeEndUtc"
              isStart={false}
            />
          </Stack>
        </StackItem>

        <StackItem>
          <Heading labelPaddings>
            Initial Installation Window (UTC Time)
          </Heading>
          <Stack tokens={{ childrenGap: theme.spacing.m }}>
            <RolloutTimePicker
              rolloutOccurred={rolloutOccurred}
              datePickerName="initialInstallDateStartUtc"
              timePickerName="initialInstallTimeStartUtc"
              isStart={true}
            />

            <RolloutTimePicker
              rolloutOccurred={rolloutOccurred}
              datePickerName="initialInstallDateEndUtc"
              timePickerName="initialInstallTimeEndUtc"
              isStart={false}
            />
          </Stack>
        </StackItem>
      </Stack>

      <Stack horizontal horizontalAlign="space-between">
        <Text variant="xLarge">Software Packages</Text>

        <Stack.Item align="end">
          <DefaultButton
            iconProps={{ iconName: "Add" }}
            onClick={() => setShowAdd(true)}
            disabled={rolloutOccurred || !hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            Add Software Package
          </DefaultButton>
        </Stack.Item>
      </Stack>

      <RolloutPackages
        onConfigureRolloutPackage={configureRolloutPackage}
        rolloutOccurred={rolloutOccurred}
      />

      <Stack horizontal>
        <PrimaryButton type="submit" disabled={!form.formState.isDirty}>
          Submit
        </PrimaryButton>
      </Stack>

      <AddRolloutPackageDialog
        hidden={!showAdd}
        onDismiss={() => setShowAdd(false)}
      />

      {configureSoftwarePackage && (
        <RolloutPackageDesiredConfigDialog
          hidden={!showConfigure}
          onDismiss={() => setShowConfigure(false)}
          onUpdate={onUpdateSoftwarePackageDesiredConfigs}
          configureSoftwarePackage={configureSoftwarePackage}
          rolloutOccurred={rolloutOccurred}
        />
      )}
    </Stack>
  );
};
