import React, { MouseEvent, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  IButtonProps,
  IconButton,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  Toggle,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { DangerMenuItem } from "@components/buttons/DangerButton";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { IsDevelopmentEnvironment } from "@libs/config/config";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { hasRolloutOccurred } from "../utils";
import { DeleteRolloutDialog } from "./DeleteRolloutDialog";
import { DuplicateRolloutDialog } from "./DuplicateRolloutDialog";

interface RolloutListItemProps {
  rollout: Rollout;
}

export const RolloutListItem: React.FC<RolloutListItemProps> = ({
  rollout
}) => {
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showDuplicate, setShowDuplicate] = useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const rolloutOccurred = hasRolloutOccurred(rollout);

  const onShowDuplicate = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowDuplicate(true);
  };

  const onShowDelete = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowDelete(true);
  };

  const selectRollout = e => {
    e.preventDefault();
    const to = generatePath(
      "/system/plt/deployment-rings/:ringId/rollouts/:rolloutId",
      {
        ringId: rollout.deploymentRingId,
        rolloutId: rollout.id
      }
    );
    navigate(to);
  };

  return (
    <Stack
      styles={{
        root: {
          "& .RolloutListItemActions": {
            opacity: 0
          },
          "&:hover .RolloutListItemActions": {
            opacity: 1
          }
        }
      }}
    >
      <DeleteRolloutDialog
        rollout={rollout}
        hidden={!showDelete}
        onCancel={() => setShowDelete(false)}
        setShowDelete={setShowDelete}
      />

      <DuplicateRolloutDialog
        rollout={rollout}
        hidden={!showDuplicate}
        onDismiss={() => setShowDuplicate(false)}
      />

      <Stack
        tokens={{ childrenGap: theme.spacing.s1 }}
        styles={{
          root: {
            borderColor: theme.semanticColors.variantBorder,
            borderWidth: 1,
            borderStyle: "solid",
            padding: theme.spacing.s1,
            "&:focus, &:hover, &:active, &:hover:active": {
              backgroundColor: theme.semanticColors.bodyBackgroundHovered,
              textDecoration: "none",
              cursor: "pointer"
            }
          }
        }}
        onClick={selectRollout}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
            <Text variant="large">{rollout.displayName}</Text>

            {rolloutOccurred ? (
              <TextBadge
                badgeColor={TextBadgeColor.lightGrey}
                badgeSize={TextBadgeSize.small}
              >
                Read Access
              </TextBadge>
            ) : (
              <TextBadge
                badgeColor={TextBadgeColor.yellow}
                badgeSize={TextBadgeSize.small}
              >
                Edit Access
              </TextBadge>
            )}

            {IsDevelopmentEnvironment && rolloutOccurred && (
              <TextBadge
                badgeColor={TextBadgeColor.blue}
                badgeSize={TextBadgeSize.small}
              >
                Dev Edit Access
              </TextBadge>
            )}
          </Stack>
          <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
            <IconButton
              styles={{
                root: { justifySelf: "end" },
                rootHovered: {
                  borderColor:
                    theme.semanticColors.listItemBackgroundCheckedHovered,
                  background:
                    theme.semanticColors.listItemBackgroundCheckedHovered
                },
                rootExpanded: {
                  borderColor:
                    theme.semanticColors.listItemBackgroundCheckedHovered,
                  background:
                    theme.semanticColors.listItemBackgroundCheckedHovered
                }
              }}
              menuIconProps={{ iconName: "more" }}
              menuProps={{
                items: [
                  {
                    key: "duplicate",
                    text: "Duplicate",
                    title: "Duplicate this rollout.",
                    onClick: onShowDuplicate,
                    iconProps: { iconName: "duplicateRow" }
                  },
                  {
                    key: "delete",
                    text: "Delete",
                    disabled: rolloutOccurred && !IsDevelopmentEnvironment,
                    title:
                      rolloutOccurred && !IsDevelopmentEnvironment
                        ? "This rollout has already occurred and cannot be deleted. To update, create a new rollout"
                        : "Delete this rollout.",
                    onClick: onShowDelete,
                    iconProps: { iconName: "delete" },
                    onRender: (item: IButtonProps) => (
                      <DangerMenuItem {...item} />
                    )
                  }
                ]
              }}
              disabled={!hasWritePermission}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            />

            <Toggle
              automationAttribute="enabled-toggle"
              styles={{
                root: { margin: 0 },
                label: { marginLeft: theme.spacing.s1 }
              }}
              label="Enabled"
              inlineLabel
              checked={rollout.isActive}
              disabled
            />
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: theme.spacing.l2 }}>
          <Stack styles={{ root: { flex: 1, textAlign: "center" } }}>
            <Text>Download Window</Text>
            <Stack
              horizontal
              tokens={{ childrenGap: theme.spacing.s1 }}
              horizontalAlign="center"
            >
              <DateTimeBadge
                dateTime={rollout.initialDownloadStartUtc}
                badgeColor={TextBadgeColor.green}
              />
              <Text variant="small">TO</Text>

              <DateTimeBadge
                dateTime={rollout.initialDownloadEndUtc}
                badgeColor={TextBadgeColor.red}
              />
            </Stack>
          </Stack>
          <Stack styles={{ root: { flex: 1, textAlign: "center" } }}>
            <Text>Installation Window</Text>
            <Stack
              horizontal
              tokens={{ childrenGap: theme.spacing.s1 }}
              horizontalAlign="center"
            >
              <DateTimeBadge
                dateTime={rollout.initialInstallStartUtc}
                badgeColor={TextBadgeColor.green}
              />
              <Text variant="small">TO</Text>

              <DateTimeBadge
                dateTime={rollout.initialInstallEndUtc}
                badgeColor={TextBadgeColor.red}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const DateTimeBadge: React.FC<DateTimeBadgeProps> = ({
  dateTime,
  badgeColor
}) => {
  return (
    <TooltipHost
      content={dateTime
        ?.setZone(DateTime.now().timeZone)
        .toFormat(DATE_FORMATS.LOCALIZED_DATE_TIME)}
    >
      <TextBadge badgeSize={TextBadgeSize.small} badgeColor={badgeColor}>
        {dateTime?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)}
      </TextBadge>
    </TooltipHost>
  );
};

interface DateTimeBadgeProps {
  dateTime?: DateTime;
  badgeColor: TextBadgeColor;
}
