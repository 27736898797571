import { useRef } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import {
  DefaultButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsListProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { SoftwarePackageType } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { RolloutPackageSoftwarePackageSummaryDto } from "./";
import {
  RolloutPackageVersionFilter,
  RolloutPackageVersionFilterValues
} from "./RolloutPackageVersionFilter";
import { RolloutPackageVersionTable } from "./RolloutPackageVersionTable";

export interface AddRolloutPackagesFormValues {
  packages: AddRolloutPackageFormValues[];
}

export interface AddRolloutPackageFormValues {
  softwarePackageId: string;
  code: string;
  displayName: string;
  description?: string;
  softwarePackageType: SoftwarePackageType;
  softwarePublisher: string;
  softwarePackageVersionId: string;
}

interface AddRolloutPackageProps extends ShimmeredDetailsListProps {
  onDismiss: () => void;
  onAdd: (packages: AddRolloutPackageFormValues[]) => void;
}

export const AddRolloutPackage = ({
  items,
  onDismiss,
  onAdd,
  ...props
}: AddRolloutPackageProps) => {
  const theme = useTheme();
  const { control, ...form } = useForm<AddRolloutPackagesFormValues>({
    defaultValues: {
      packages: []
    }
  });

  const { fields: packages, replace } = useFieldArray({
    name: "packages",
    control
  });

  const updateRolloutPackageVersion = (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => {
    const updatedSoftwareVersions = [...packages];
    const existingSoftwareVersion = updatedSoftwareVersions.find(
      x => x.softwarePackageId === softwarePackageId
    );

    if (existingSoftwareVersion)
      existingSoftwareVersion.softwarePackageVersionId = softwarePackageVersionId;

    replace(updatedSoftwareVersions);
  };

  const selection = useRef(
    new Selection({
      onSelectionChanged() {
        const dtos = selection.current.getSelection() as AddRolloutPackageFormValues[];
        replace(dtos);
      }
    })
  );

  const filteredSelections = packages ?? [];

  const close = () => {
    // reset the selected rollout device configs
    replace([]);
    selection.current.setItems([], true);
    onDismiss();
  };

  const handleSubmit = () => {
    onAdd(packages);
    onDismiss();
  };

  return (
    <FormProvider {...{ control, ...form }}>
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <RolloutPackageVersionFilter>
          {({ values }) => {
            return (
              <RolloutPackageVersionTable
                onUpdateRolloutPackageVersion={updateRolloutPackageVersion}
                items={filterAndSortSoftwarePackages(items, values)}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.multiple}
                selection={selection.current}
                selections={packages ?? []}
                setKey="software-package-version-table"
                {...props}
              />
            );
          }}
        </RolloutPackageVersionFilter>

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="end"
        >
          <DefaultButton
            disabled={filteredSelections.length === 0}
            onClick={handleSubmit}
          >
            <span>
              Add
              {filteredSelections.length > 0
                ? ` (${filteredSelections.length})`
                : ""}
            </span>
          </DefaultButton>
          <DefaultButton onClick={close}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

const filterAndSortSoftwarePackages = (
  softwarePackagesData: RolloutPackageSoftwarePackageSummaryDto[],
  filter: RolloutPackageVersionFilterValues
): RolloutPackageSoftwarePackageSummaryDto[] => {
  return softwarePackagesData
    .filter(
      d =>
        filter.name === "" ||
        d.displayName.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0
    )
    .sort(
      (
        a: RolloutPackageSoftwarePackageSummaryDto,
        b: RolloutPackageSoftwarePackageSummaryDto
      ) => {
        return a.displayName < b.displayName ? 1 : -1;
      }
    );
};
