import {
  Rollout,
  SoftwarePackageVersion
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

export const parseRollout = (
  rollout?: Rollout,
  softwarePackageVersions?: SoftwarePackageVersion[]
): RolloutFormValues => {
  return {
    ...rollout,
    initialDownloadTimeStartUtc: rollout?.initialDownloadStartUtc?.toTimeInputFormat(),
    initialDownloadTimeEndUtc: rollout?.initialDownloadEndUtc?.toTimeInputFormat(),
    initialInstallTimeStartUtc: rollout?.initialInstallStartUtc?.toTimeInputFormat(),
    initialInstallTimeEndUtc: rollout?.initialInstallEndUtc?.toTimeInputFormat(),
    initialDownloadDateStartUtc: rollout?.initialDownloadStartUtc?.toJSDate(),
    initialDownloadDateEndUtc: rollout?.initialDownloadEndUtc?.toJSDate(),
    initialInstallDateStartUtc: rollout?.initialInstallStartUtc?.toJSDate(),
    initialInstallDateEndUtc: rollout?.initialInstallEndUtc?.toJSDate(),
    rolloutPackages:
      rollout?.rolloutPackages.map(rolloutPackage => {
        const softwarePackageVersion = softwarePackageVersions?.find(
          x => x.id === rolloutPackage.softwarePackageVersionId
        );

        return {
          rolloutPackageId: rolloutPackage.id,
          softwarePackageId: softwarePackageVersion?.softwarePackageId,
          softwarePackageVersionId: rolloutPackage.softwarePackageVersionId,
          eTag: rolloutPackage.eTag,
          desiredConfigs: rolloutPackage.rolloutPackageDesiredConfigs.map(
            desiredConfig => {
              return {
                id: desiredConfig.id,
                key: desiredConfig.key,
                value: desiredConfig.value,
                eTag: desiredConfig.eTag,
                softwarePackageId: softwarePackageVersion?.softwarePackageId
              } as RolloutPackageDesiredConfigFormValues;
            }
          )
        } as RolloutPackageFormValues;
      }) ?? []
  };
};

export interface RolloutFormValues {
  displayName?: string;
  isActive?: boolean;
  initialDownloadTimeStartUtc?: string;
  initialDownloadTimeEndUtc?: string;
  initialInstallTimeStartUtc?: string;
  initialInstallTimeEndUtc?: string;
  initialDownloadDateStartUtc?: Date;
  initialDownloadDateEndUtc?: Date;
  initialInstallDateStartUtc?: Date;
  initialInstallDateEndUtc?: Date;
  rolloutPackages?: RolloutPackageFormValues[];
  eTag?: string;
}

export interface RolloutPackageFormValues {
  rolloutPackageId?: string;
  softwarePackageId: string;
  softwarePackageVersionId: string;
  eTag?: string;
  desiredConfigs: RolloutPackageDesiredConfigFormValues[];
}

export interface RolloutPackageDesiredConfigFormValues {
  id?: string;
  key: string;
  value?: string;
  softwarePackageId: string;
  eTag?: string;
}
