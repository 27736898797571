import { DetailsRow, IDetailsRowProps } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { RolloutSoftwarePackageVersionDto } from "./";

export interface RolloutPackageTableRowProps extends IDetailsRowProps {}

export const RolloutPackageTableRow = (props: RolloutPackageTableRowProps) => {
  const { item, ...rest } = props;

  // Patch in the details of this software package version
  const softwarePackageQuery = useSoftwarePackageVersion(
    item.softwarePackageVersionId
  );

  const noData = <>No rollout package available.</>;

  return (
    <QueryStateIndicator<SoftwarePackageVersion>
      NoDataNode={noData}
      {...softwarePackageQuery}
    >
      {softwarePackageVersion => {
        const data = {
          ...item,
          version: softwarePackageVersion?.version,
          datePublishedUtc: softwarePackageVersion?.datePublishedUtc,
          minimumWindowsVersion: softwarePackageVersion?.minimumWindowsVersion,
          maximumWindowsVersion: softwarePackageVersion?.maximumWindowsVersion,
          softwarePublisherCode: softwarePackageVersion?.softwarePublisherCode
        } as RolloutSoftwarePackageVersionDto;

        const row = {
          ...rest,
          item: data
        } as IDetailsRowProps;

        return <DetailsRow {...row} />;
      }}
    </QueryStateIndicator>
  );
};
