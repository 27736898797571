import { useParams } from "react-router-dom";

import {
  useRollout,
  useSoftwarePackageVersionsForRollout
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { RolloutHost } from "./";

export const EditRollout: React.FC = () => {
  const { rolloutId } = useParams<{
    rolloutId: string;
  }>();

  const { data: rollout, isLoading: rolloutIsLoading } = useRollout(rolloutId!);

  const {
    data: softwarePackageVersionsForRollout,
    isLoading: softwarePackageVersionsForRolloutIsLoading
  } = useSoftwarePackageVersionsForRollout(rolloutId!);

  const isLoading =
    rolloutIsLoading || softwarePackageVersionsForRolloutIsLoading;

  if (isLoading) return null;

  return (
    <RolloutHost
      rollout={rollout}
      softwarePackageVersionsForRollout={softwarePackageVersionsForRollout}
      modeLabel="Edit"
    />
  );
};
