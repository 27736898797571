import React, { FunctionComponent } from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { useBusinessUserSearch } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { useSearchUsers } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface InviteFormUserCheckProps {
  email?: string;
  tenantId: string;
}

export const InviteFormUserCheck: FunctionComponent<InviteFormUserCheckProps> = ({
  email,
  tenantId
}) => {
  const { data, isFetching } = useBusinessUserSearch({ email });

  const { isLoading, data: tenantUsers } = useSearchUsers({ tenantId });

  const checkEmailExists = data => {
    if (data?.tooManyResults) return true;
    if (data?.records?.length === 0) return false;

    const matchingBpIds = tenantUsers?.map(user => user.bpIdUserId ?? "") ?? [];

    return data?.records?.filter(x => matchingBpIds.includes(x.id)).length > 0;
  };

  return isFetching || isLoading ? (
    <MessageBar messageBarType={MessageBarType.info}>
      Checking for this email in this tenant...
    </MessageBar>
  ) : (
    <>
      {checkEmailExists(data) && (
        <MessageBar messageBarType={MessageBarType.warning}>
          This email already exists in this tenant.
        </MessageBar>
      )}
    </>
  );
};
