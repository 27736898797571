import { IDropdownOption } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSoftwarePackageVersions } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SoftwarePackageVersionDropDown } from "../../../common/SoftwarePackageVersionDropDown";
import { RolloutPackageSoftwarePackageSummaryDto } from "./";
import { AddRolloutPackageFormValues } from "./AddRolloutPackage";

export interface RolloutPackageVersionTableDropDownProps {
  softwarePackage: RolloutPackageSoftwarePackageSummaryDto;
  selection?: AddRolloutPackageFormValues;
  onUpdateSoftwarePackageVersion: (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => void;
}

export const RolloutPackageVersionTableDropDown = ({
  softwarePackage,
  selection,
  onUpdateSoftwarePackageVersion
}: RolloutPackageVersionTableDropDownProps) => {
  const softwarePackageVersionsQuery = useSoftwarePackageVersions({
    softwarePackageId: softwarePackage.softwarePackageId
  });

  const noData = <>No versions available.</>;

  return (
    <QueryStateIndicator<SoftwarePackageVersion[]>
      NoDataNode={noData}
      {...softwarePackageVersionsQuery}
    >
      {versions => {
        const versionOptions =
          versions.map(x => {
            return {
              key: x.id,
              text: x.version as string,
              data: x
            } as IDropdownOption;
          }) ?? [];

        const defaultSelection =
          versionOptions.length > 0 ? versionOptions[0].key : undefined;

        const selectedKey =
          selection?.softwarePackageVersionId ?? defaultSelection;

        return (
          <SoftwarePackageVersionDropDown
            selectedKey={selectedKey}
            name="softwarePackageVersionId"
            options={versionOptions}
            onChange={(e, option) =>
              onUpdateSoftwarePackageVersion(
                softwarePackage.softwarePackageId,
                String(option?.key)
              )
            }
          />
        );
      }}
    </QueryStateIndicator>
  );
};
