import { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import {
  MessageBar,
  MessageBarType,
  Stack,
  TopBarDefaultHeader,
  useTheme
} from "@bps/fluent-ui";
import { validationResolver } from "@components/form/validation/validation-resolver";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import {
  Rollout,
  RolloutCreateRequest,
  SoftwarePackageVersion
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useCreateRollout,
  useUpdateRollout
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { IsDevelopmentEnvironment } from "@libs/config/config";
import { Permissions } from "@libs/permissions/permissions.enum";

import { convertToDateTime, hasRolloutOccurred } from "../utils";
import { confirmRolloutPast } from "./confirmRolloutPast";
import { RolloutForm } from "./RolloutForm";
import {
  rolloutFormSchema,
  rolloutFormValidator
} from "./RolloutFormValidator";
import { parseRollout, RolloutFormValues } from "./rolloutFormValues";

interface RolloutHostBaseProps {
  rollout?: Rollout;
  softwarePackageVersionsForRollout?: SoftwarePackageVersion[];
  defaultName?: string;
  modeLabel: "Edit" | "New";
}

const RolloutHostBase: React.FC<RolloutHostBaseProps> = ({
  rollout: existingRollout,
  softwarePackageVersionsForRollout,
  defaultName,
  modeLabel
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { ringId } = useParams<{
    ringId: string;
  }>();

  const backToRings = useCallback(() => {
    const to = generatePath("/system/plt/deployment-rings/:ringId", {
      ringId
    });
    navigate(to);
  }, [navigate, ringId]);

  const { mutateAsync: createRollout } = useCreateRollout();
  const { mutateAsync: updateRollout } = useUpdateRollout();

  const rollout = useMemo(() => {
    return (
      existingRollout ??
      ({
        deploymentRingId: ringId!,
        isActive: true,
        displayName: defaultName,
        rolloutPackages: []
      } as Rollout)
    );
  }, [existingRollout, ringId, defaultName]);

  const { control, ...form } = useForm<RolloutFormValues>({
    defaultValues: parseRollout(rollout, softwarePackageVersionsForRollout),
    resolver: values =>
      validationResolver(
        (values: RolloutFormValues) =>
          rolloutFormValidator.validateWithParse(values, rolloutFormSchema),
        values
      )
  });

  const rolloutOccurred =
    hasRolloutOccurred(rollout) && !IsDevelopmentEnvironment;

  const onSubmit = (values: RolloutFormValues) => {
    const {
      isActive,
      initialDownloadDateStartUtc,
      initialDownloadDateEndUtc,
      initialDownloadTimeStartUtc,
      initialDownloadTimeEndUtc,
      initialInstallDateStartUtc,
      initialInstallDateEndUtc,
      initialInstallTimeStartUtc,
      initialInstallTimeEndUtc,
      rolloutPackages,
      ...rest
    } = values;

    const downloadStartUtc = convertToDateTime(
      initialDownloadDateStartUtc!,
      initialDownloadTimeStartUtc!
    );

    const downloadEndUtc = convertToDateTime(
      initialDownloadDateEndUtc!,
      initialDownloadTimeEndUtc!
    );

    const installStartUtc = convertToDateTime(
      initialInstallDateStartUtc!,
      initialInstallTimeStartUtc!
    );

    const installEndUtc = convertToDateTime(
      initialInstallDateEndUtc!,
      initialInstallTimeEndUtc!
    );

    const request = {
      ...rest,
      isActive: isActive!,
      deploymentRingId: ringId!,
      initialDownloadStartUtc: downloadStartUtc.toISO(),
      initialDownloadEndUtc: downloadEndUtc.toISO(),
      initialInstallStartUtc: installStartUtc.toISO(),
      initialInstallEndUtc: installEndUtc.toISO(),
      rolloutPackages:
        rolloutPackages?.map(rolloutPackage => {
          return {
            rolloutPackageId: rolloutPackage.rolloutPackageId,
            softwarePackageVersionId: rolloutPackage.softwarePackageVersionId,
            eTag: rolloutPackage.eTag
          };
        }) ?? [],
      rolloutPackagesDesiredConfigs: rolloutPackages
        ?.map(
          rolloutPackage =>
            rolloutPackage.desiredConfigs?.map(desiredConfig => {
              return {
                rolloutPackageDesiredConfigId: desiredConfig.id,
                key: desiredConfig.key,
                value: desiredConfig.value,
                softwarePackageVersionId: rolloutPackages.find(
                  x => x.softwarePackageId === desiredConfig.softwarePackageId
                )?.softwarePackageVersionId,
                eTag: desiredConfig.eTag
              };
            }) ?? []
        )
        .flat()
    } as RolloutCreateRequest;

    confirmRolloutPast({
      downloadStartUtc,
      async changeState() {
        if (!!!rollout.id) {
          await createRollout(request);
        } else {
          await updateRollout({
            ...request,
            rolloutId: rollout.id
          });
        }

        backToRings();
      }
    });
  };

  return (
    <FormProvider {...{ control, ...form }}>
      <Stack verticalFill>
        <Stack
          verticalFill
          styles={{
            root: { padding: theme.spacing.s1, width: "100%" }
          }}
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Stack horizontal>
            <TopBarDefaultHeader
              backButtonOnClick={backToRings}
              heading="Back to rings"
            />
            <SectionTitle>{modeLabel} Rollout</SectionTitle>
          </Stack>

          {rolloutOccurred && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              dismissButtonAriaLabel="Close"
            >
              This rollout has already occurred and cannot be updated. To update
              software packages, create a new rollout.
            </MessageBar>
          )}

          <RolloutForm rollout={rollout} onSubmit={onSubmit} />
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export const RolloutHost = withPermissions(
  RolloutHostBase,
  [Permissions.PltFieldManagementRead, Permissions.PltFieldManagementWrite],
  "or"
);
