import { useFormContext, useWatch } from "react-hook-form";

import { confirm } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  SoftwarePackage,
  SoftwarePackageType
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSoftwarePackages } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import {
  RolloutFormValues,
  RolloutPackageFormValues
} from "../rollout/rolloutFormValues";
import {
  RolloutPackageFilter,
  RolloutPackageFilterValues
} from "./RolloutPackageFilter";
import { RolloutPackageTable } from "./RolloutPackageTable";

// View of each software package assigned to this rollout
export interface RolloutSoftwarePackageVersionDto {
  id: string;
  code: string;
  displayName: string;
  description?: string;
  softwarePackageType: SoftwarePackageType;
  softwarePackageVersionId: string;
  softwarePackageId: string;
  softwarePublisherCode: string;
  version: string;
  datePublishedUtc?: DateTime;
  minimumWindowsVersion: string;
  maximumWindowsVersion: string;
  rolloutId: string;
  rolloutPackageId: string;
}

interface RolloutPackagesProps {
  onConfigureRolloutPackage: (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => void;
  rolloutOccurred: boolean;
}

export const RolloutPackages = ({ ...props }: RolloutPackagesProps) => {
  const { control, ...form } = useFormContext<RolloutFormValues>();

  const rolloutPackages: RolloutPackageFormValues[] = useWatch({
    name: "rolloutPackages"
  });

  const setRolloutPackages = (rolloutPackages: RolloutPackageFormValues[]) => {
    form.setValue("rolloutPackages", rolloutPackages, { shouldDirty: true });
  };

  const softwarePackagesQuery = useSoftwarePackages();

  const {
    data: softwarePackagesData,
    isLoading: softwarePackagesIsLoading
  } = softwarePackagesQuery;

  const onDelete = async (softwarePackageId: string) => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Delete"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: "Delete software package",
        subText:
          "Are you sure you want to remove this software package from the rollout?"
      }
    });

    if (isConfirmed) {
      onRemoveSoftwarePackage(softwarePackageId);
    }
  };

  const onUpdateSoftwarePackageVersion = (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => {
    const updatedRolloutPackages = rolloutPackages.map(x =>
      x.softwarePackageId === softwarePackageId
        ? {
            ...x,
            softwarePackageVersionId
          }
        : x
    );

    setRolloutPackages(updatedRolloutPackages);
  };

  const onRemoveSoftwarePackage = (softwarePackageId: string) => {
    const updatedRolloutPackages = rolloutPackages.filter(
      x => x.softwarePackageId !== softwarePackageId
    );

    setRolloutPackages(updatedRolloutPackages);
  };

  if (!softwarePackagesData) return null;

  const items = rolloutPackages.map(rp =>
    mapRolloutSoftwarePackageVersionDto(rp, softwarePackagesData)
  );

  return (
    <RolloutPackageFilter>
      {({ values }) => {
        return (
          <RolloutPackageTable
            items={filterAndSortRolloutSoftwarePackageVersions(items, values)}
            onDelete={onDelete}
            onUpdateSoftwarePackageVersion={onUpdateSoftwarePackageVersion}
            selectionPreservedOnEmptyClick
            setKey="rollout-package-table"
            enableShimmer={softwarePackagesIsLoading}
            {...props}
          />
        );
      }}
    </RolloutPackageFilter>
  );
};

const mapRolloutSoftwarePackageVersionDto = (
  rolloutPackage: RolloutPackageFormValues,
  softwarePackages: SoftwarePackage[]
) => {
  const softwarePackage = softwarePackages.find(
    x => x.id === rolloutPackage.softwarePackageId
  );

  const data = {
    id: rolloutPackage.softwarePackageId,
    code: softwarePackage?.code,
    displayName: softwarePackage?.displayName,
    description: softwarePackage?.description,
    softwarePackageType: softwarePackage?.softwarePackageType,
    softwarePackageVersionId: rolloutPackage.softwarePackageVersionId,
    softwarePackageId: softwarePackage?.id
  } as RolloutSoftwarePackageVersionDto;

  return data;
};

const filterAndSortRolloutSoftwarePackageVersions = (
  rolloutSoftwarePackageVersions: RolloutSoftwarePackageVersionDto[],
  filter: RolloutPackageFilterValues
): RolloutSoftwarePackageVersionDto[] => {
  return rolloutSoftwarePackageVersions
    .filter(
      d =>
        filter.name === "" ||
        d.displayName.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0
    )
    .sort(
      (
        a: RolloutSoftwarePackageVersionDto,
        b: RolloutSoftwarePackageVersionDto
      ) => {
        return a.displayName < b.displayName ? 1 : -1;
      }
    );
};
