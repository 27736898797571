import { useFieldArray, useForm, useWatch } from "react-hook-form";

import {
  DefaultButton,
  NoDataTile,
  ShimmeredDetailsListProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { SoftwarePackageVersionConfig } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { ChangeLogDto } from "@libs/api/types/common-dtos";

import {
  RolloutPackageDesiredConfigFormValues,
  RolloutPackageFormValues
} from "../../rollout/rolloutFormValues";
import { RolloutPackageFilterValues } from "../RolloutPackageFilter";
import { RolloutPackageDesiredConfigFilter } from "./RolloutPackageDesiredConfigFilter";
import { RolloutPackageDesiredConfigTable } from "./RolloutPackageDesiredConfigTable";

export interface RolloutPackageConfigurationDto {
  softwarePackageVersionId: string;
  key: string;
  value: string;
  defaultValue: string;
  dataType: string;
  description: string;
  changeLog: ChangeLogDto;
}

interface EditRolloutPackageDesiredConfigProps
  extends ShimmeredDetailsListProps {
  softwarePackageId: string;
  onDismiss: () => void;
  onUpdate: (values: RolloutPackageDesiredConfigFormValues[]) => void;
  rolloutOccurred?: boolean;
}

export const EditRolloutPackageDesiredConfig = ({
  items,
  softwarePackageId,
  onDismiss,
  onUpdate,
  rolloutOccurred,
  ...props
}: EditRolloutPackageDesiredConfigProps) => {
  const theme = useTheme();

  const rolloutPackages: RolloutPackageFormValues[] = useWatch({
    name: "rolloutPackages"
  });

  const rolloutPackage = rolloutPackages.find(
    rolloutPackage => rolloutPackage.softwarePackageId === softwarePackageId
  );

  const { control, ...form } = useForm<RolloutPackageFormValues>({
    defaultValues: rolloutPackage
  });

  const { fields: rolloutPackageDesiredConfigs, replace } = useFieldArray({
    name: "desiredConfigs",
    control
  });

  const combinedItems = getCombinedItems(items, rolloutPackageDesiredConfigs);

  const handleUpdateRolloutPackageDesiredConfig = (
    key: string,
    value: string
  ) => {
    const updatedConfigs = [...rolloutPackageDesiredConfigs];
    const existingConfig = updatedConfigs.find(
      desiredConfig => desiredConfig.key === key
    );

    if (existingConfig) {
      existingConfig.value = value;
    } else {
      updatedConfigs.push({
        id: "",
        key,
        value,
        softwarePackageId: rolloutPackage?.softwarePackageId!
      });
    }

    replace(updatedConfigs);
  };

  const handleDeleteRolloutPackageDesiredConfig = (key: string) => {
    const updatedConfigs = rolloutPackageDesiredConfigs.filter(
      desiredConfig => desiredConfig.key !== key
    );

    replace(updatedConfigs);
  };

  const save = async () => {
    onUpdate(rolloutPackageDesiredConfigs);
    onDismiss();
  };

  return (
    <Stack tokens={{ childrenGap: theme.spacing.m }}>
      <RolloutPackageDesiredConfigFilter>
        {({ values }) => {
          const filteredValues = filterAndSortDesiredConfigs(
            combinedItems,
            values
          );

          if (filteredValues.length === 0) {
            return (
              <NoDataTile
                styles={{ root: { height: 500 } }}
                showBoxShadow={false}
                textProps={{ text: "No configuration settings found." }}
                linkProps={{}}
              />
            );
          }

          return (
            <RolloutPackageDesiredConfigTable
              onUpdateRolloutPackageDesiredConfig={
                handleUpdateRolloutPackageDesiredConfig
              }
              onDeleteRolloutPackageDesiredConfig={
                handleDeleteRolloutPackageDesiredConfig
              }
              items={filteredValues}
              selectionPreservedOnEmptyClick
              setKey="rollout-package-desired-config-table"
              {...props}
              rolloutOccurred={rolloutOccurred}
            />
          );
        }}
      </RolloutPackageDesiredConfigFilter>

      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.s1 }}
        horizontalAlign="end"
      >
        <DefaultButton
          disabled={rolloutOccurred || !form.formState.isDirty}
          onClick={save}
        >
          <span>Save</span>
        </DefaultButton>
        <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
      </Stack>
    </Stack>
  );
};

const filterAndSortDesiredConfigs = (
  desiredConfigs: RolloutPackageConfigurationDto[],
  filter: RolloutPackageFilterValues
): RolloutPackageConfigurationDto[] => {
  return desiredConfigs
    .filter(
      d =>
        filter.name === "" ||
        d.key.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0
    )
    .sort(
      (
        a: RolloutPackageConfigurationDto,
        b: RolloutPackageConfigurationDto
      ) => {
        return a.key < b.key ? -1 : 1;
      }
    );
};

// Combine software package version configs and rollout package desired configs into a set of dtos for this rollout package
function getCombinedItems(
  softwarePackageVersionConfigs: SoftwarePackageVersionConfig[],
  rolloutPackageDesiredConfigs: RolloutPackageDesiredConfigFormValues[]
): RolloutPackageConfigurationDto[] {
  const configurations = softwarePackageVersionConfigs.map(
    softwarePackageVersionConfig => {
      const desiredConfig = rolloutPackageDesiredConfigs?.find(
        x => x.key === softwarePackageVersionConfig.key
      );

      const {
        key,
        softwarePackageVersionId,
        defaultValue,
        dataType,
        description
      } = softwarePackageVersionConfig;

      return {
        value:
          desiredConfig?.value ?? softwarePackageVersionConfig?.defaultValue,
        key,
        softwarePackageVersionId,
        defaultValue,
        dataType,
        description
      } as RolloutPackageConfigurationDto;
    }
  );

  return configurations;
}
