import { compareVersions } from "compare-versions";

import { Option, Text } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { SoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSoftwarePackageVersions } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { SoftwarePackageVersionDropDown } from "../../common/SoftwarePackageVersionDropDown";
import { RolloutSoftwarePackageVersionDto } from "./";

interface RolloutPackageVersionSelectProps {
  rolloutSoftwarePackageVersion: RolloutSoftwarePackageVersionDto;
  onUpdate: (softwarePackageVersionId: string) => void;
  rolloutOccurred: boolean;
}

export const RolloutPackageVersionSelect: React.FC<RolloutPackageVersionSelectProps> = ({
  onUpdate,
  rolloutSoftwarePackageVersion,
  rolloutOccurred
}) => {
  const softwarePackageVersionsQuery = useSoftwarePackageVersions({
    softwarePackageId: rolloutSoftwarePackageVersion.softwarePackageId
  });

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const {
    data: softwarePackageVersionsData,
    error: softwarePackageVersionsError,
    isLoading: softwarePackageVersionsIsLoading
  } = softwarePackageVersionsQuery;

  const getVersions = (): Option<SoftwarePackageVersion>[] => {
    if (!softwarePackageVersionsData) return [];

    return (
      softwarePackageVersionsData
        .sort((a, b) => compareVersions(b.version, a.version))
        .map(softwarePackageVersion => {
          return {
            key: softwarePackageVersion.id,
            data: softwarePackageVersion,
            text: softwarePackageVersion.version
          };
        }) ?? []
    );
  };

  return (
    <QueryStateIndicator {...softwarePackageVersionsQuery}>
      {() => {
        if (softwarePackageVersionsIsLoading) return <Text>Loading...</Text>;

        return (
          <SoftwarePackageVersionDropDown
            errorMessage={softwarePackageVersionsError?.message}
            disabled={rolloutOccurred || !hasWritePermission}
            name="RolloutPackageVersionSelect"
            selectedKey={rolloutSoftwarePackageVersion.softwarePackageVersionId}
            options={getVersions()}
            onChange={(_, option) => {
              onUpdate(String(option?.key));
            }}
          />
        );
      }}
    </QueryStateIndicator>
  );
};
