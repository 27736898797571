import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { useSoftwarePackageVersionConfig } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { RolloutPackageDesiredConfigFormValues } from "../../rollout/rolloutFormValues";
import { EditRolloutPackageDesiredConfig } from "./EditRolloutPackageDesiredConfig";

export interface RolloutPackageConfigureDto {
  softwarePackageId: string;
  softwarePackageVersionId: string;
}

interface RolloutPackageDesiredConfigDialogProps extends IDialogProps {
  configureSoftwarePackage: RolloutPackageConfigureDto;
  onDismiss: () => void;
  onUpdate: (values: RolloutPackageDesiredConfigFormValues[]) => void;
  rolloutOccurred: boolean;
}

export const RolloutPackageDesiredConfigDialog: React.FC<RolloutPackageDesiredConfigDialogProps> = ({
  configureSoftwarePackage,
  onDismiss,
  onUpdate,
  rolloutOccurred,
  ...props
}) => {
  const {
    softwarePackageId,
    softwarePackageVersionId
  } = configureSoftwarePackage;

  const softwarePackageVersionConfigQuery = useSoftwarePackageVersionConfig(
    softwarePackageVersionId
  );

  const {
    data: softwarePackageVersionConfigData,
    isLoading: softwarePackageVersionConfigIsLoading,
    error: softwarePackageVersionConfigError
  } = softwarePackageVersionConfigQuery;

  const isLoading = softwarePackageVersionConfigIsLoading;

  const errorMessage = softwarePackageVersionConfigError?.message;

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Edit Rollout Package Desired Configuration"
      }}
      minWidth="90%"
      maxWidth="90%"
      onDismiss={onDismiss}
      {...props}
    >
      <EditRolloutPackageDesiredConfig
        softwarePackageId={softwarePackageId}
        items={softwarePackageVersionConfigData ?? []}
        enableShimmer={isLoading}
        errorMessage={errorMessage}
        onDismiss={onDismiss}
        onUpdate={onUpdate}
        rolloutOccurred={rolloutOccurred}
      />
    </Dialog>
  );
};
