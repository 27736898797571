import { useParams } from "react-router-dom";

import { DateTime } from "@bps/utils";
import { useDeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { RolloutHost } from "./";

export const NewRollout: React.FC = () => {
  const { ringId } = useParams<{
    ringId: string;
  }>();

  const { data, isLoading } = useDeploymentRing(ringId!);

  if (isLoading) return null;

  const dateString = DateTime.now().toFormat("yyyy-MM-dd");

  const defaultName = `${data?.displayName} ${dateString}`;

  return <RolloutHost defaultName={defaultName} modeLabel="New" />;
};
